<template>
  <div id="app" class="wrapper-page">
    <div class="fullrow bg-white">
      <page-header />
      <transition
        name="fade"
        mode="out-in"
      >
        <router-view class="wrapper-main" />
      </transition>
    </div>
    <page-footer />
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue'
import PageFooter from '@/components/PageFooter.vue'

export default {
  name: 'App',
  data() {
    return {
      customRenderTriggerEvent: 'trigger-spa-prerendering',
      dispatchItems: [
        'options/fetchOptions',
        'homepage/fetchPagedata',
        'work/fetchPagedata',
        'about/fetchPagedata',
        'contact/fetchPagedata'
      ],
      numDispatchedReady: 0,
      waitBeforeTriggerPrerenderingSPA: 2000
    }
  },
  components: {
    PageHeader,
    PageFooter
  },
  methods: {
    startPrerenderSPA() {
      if (process.env.NODE_ENV !== 'production') {
        return
      }
      setTimeout(() => {
        document.dispatchEvent(new Event(this.customRenderTriggerEvent))
      }, this.waitBeforeTriggerPrerenderingSPA)
    }
  },
  created() {
    this.dispatchItems.forEach((item) => {
      this.$store.dispatch(item)
        .then(() => {
          this.numDispatchedReady += 1
          if (this.numDispatchedReady === this.dispatchItems.length) {
            this.$nextTick(() => {
              this.startPrerenderSPA()
            })
          }
          // console.log(`
          // data dispatched - type:: ${item} || numDispatchedReady: ${this.numDispatchedReady} `)
        })
    })
  }
}
</script>
<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

#nav {
  a {
    &.router-link-exact-active {
      color: #eff30a;
    }
  }
}
</style>
