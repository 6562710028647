<template>
  <div class="wrapper-header">
    <div class="grid-container">
      <div class="grid-x grid-margin-x">

        <div class="small-12 large-4 cell">
          <div class="box-headlogo">
            <router-link
              to="/"
              class="nav-main-link"
              title="Link to homepage"
            >
              <img
                class="logo"
                src="@/assets/svg/logo-mediabolaget-torsdagskvall.svg"
                alt="Logo - Mediabolaget Torsdagskväll"
              >
            </router-link>
          </div>
        </div>

        <div class="small-8 cell">
          <!-- @TODO: refactor code - pass menu items as object -->
          <menu-primary />
        </div>

      </div>
    </div><!-- /.grid-container -->
  </div>
</template>

<script>
import MenuPrimary from '@/components/MenuPrimary.vue'

export default {
  name: 'PageHeader',
  components: {
    MenuPrimary
  }
}
</script>
