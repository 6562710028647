<template>
  <swiper
    ref="headSlider"
    :options="swiperOption"
    :class="customSliderWrapperClass"
    @click="customCallback"
  >
    <!-- slides -->
    <swiper-slide
      v-for="(slide, index) in headSliderData"
      :key="index"
      :class="customSlidesClass"
      :style="bgImageStyle(slide.slide_image_url)"
    >
      <div class="wrapper-overlay">
        <div class="overlay">
          <h3 class="title">{{ slide.overlay_title }}</h3>
          <p class="copytext" v-html="slide.overlay_copytext"></p>
        </div>
      </div>
    </swiper-slide>

    <!-- Optional controls -->
    <div class="swiper-pagination"  slot="pagination"></div>
    <div class="swiper-button-prev" slot="button-prev"></div>
    <div class="swiper-button-next" slot="button-next"></div>
    <!-- <div class="swiper-scrollbar"   slot="scrollbar"></div> -->
  </swiper>
</template>

<script>
// Swiper events API: https://swiperjs.com/api/#events
import 'swiper/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  name: 'HeadSwiper',
  props: {
    headSliderData: {
      type: Array,
      required: true
    },
    customSliderWrapperClass: {
      type: String,
      default: 'slider-default'
    },
    customSlidesClass: {
      type: String,
      default: 'slide-default'
    }
  },
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      swiperOption: {
        // autoHeight: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  computed: {
    swiper() {
      return this.$refs.headSlider.swiper
    }
  },
  methods: {
    customCallback() {
      //
    },
    bgImageStyle(imageUrl) {
      return `background-image: url('${imageUrl}');`
    }
  },
  created() {
    //
  },
  mounted() {
    // console.log('Data slider in HeadSlider.vue: ', this.headSliderData)
    // current swiper instance
    // console.log('this is current swiper instance object', this.swiper)
    // console.log('Component options: ', this.$options)
    // console.log('Component name: ', this.$options.name)
    // console.log('Components: ', this.$options.components)
    // this.swiper.slideTo(3, 1000, false)
  }
}
</script>

<style lang="scss" scoped>
.swiper-slide {
  color: #000000;
  height: 500px;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
  background-position: center center;
}

.wrapper-overlay {
  position: relative;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  .overlay {
    position: absolute;
    top: 324px;
    left: 40px;
    color: #fff;

    .title {
      font-size: 36px;
      font-size: 2.25rem;
      line-height: 36px;
      margin-bottom: 10px;
    }

    .copytext {
      font-size: 26px;
      font-size: 1.625rem;
      line-height: 33px;
    }
  }
}
</style>
