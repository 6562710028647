import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
// import AboutVue from '../views/AboutVue.vue'
import Contact from '../views/Contact.vue'
import Work from '../views/Work.vue'

// console.log('host:: ', window.location.hostname)

// vue-localized-routes-example:
// https://github.com/kskrlin/vue-localized-routes-example/blob/master/assets/js/app.js

Vue.use(VueRouter)

export const routes = [
  {
    path: '/',
    name: 'Home',
    buttonLabel: 'HEM',
    component: Home
  },
  {
    path: '/work',
    name: 'Work',
    buttonLabel: 'UPPDRAG',
    component: Work
  },
  {
    path: '/about',
    name: 'About',
    buttonLabel: 'OM OSS',
    component: About
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    buttonLabel: 'KONTAKT',
    component: Contact
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
