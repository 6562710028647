import axios from 'axios'

const apiClient = axios.create({
  // baseURL: 'http://www.torsdagskvall.xx',
  // baseURL: 'https://www.torsdagskvall.se',
  baseURL: 'https://api.torsdagskvall.com',
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export default {
  getHomepage() {
    return apiClient.get('/wp-json/homepage/v1/page')
  },
  getWork() {
    return apiClient.get('/wp-json/work/v1/page')
  },
  getAbout() {
    return apiClient.get('/wp-json/about/v1/page')
  },
  getContact() {
    return apiClient.get('/wp-json/contact/v1/page')
  },
  getOptions() {
    // return apiClient.get('/wp-json/acf/v3/options/{id}')
    return apiClient.get('/wp-json/acf/v3/options/options')
  }
}
