<template>
  <div class="pagewrapper PageComponent PageComponent--about">
    <template v-if="contact.acf.slider_images_repeater">
      <div class="wrapper-mediastage">
        <HeadSlider
          :headSliderData="contact.acf.slider_images_repeater"
          :customSliderWrapperClass="customSliderWrapperClass"
          :customSlidesClass="customSlidesClass"
        />
      </div>
    </template>
    <div class="grid-container">
      <div class="grid-x grid-margin-x">
        <div class="cell small-12 wrapper-main-copytext">
          <h2 class="contact-title">{{ options.option.opt_conf_contact_headline }}</h2>
          <span class="contact-name">{{ options.option.opt_conf_contact_name }}</span><br>
          {{ options.option.opt_conf_contact_label_phone }}
          <a
            v-if="options.option.opt_conf_contact_phone"
            :href="phoneLink"
            class="phonenumber"
          >
            {{ options.option.opt_conf_contact_phone }}
          </a><br>
          <a
            v-if="options.option.opt_conf_contact_email"
            :href="mailtoLink"
            class="email"
          >
            {{ options.option.opt_conf_contact_email }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import HeadSlider from '@/components/HeadSlider.vue'

export default {
  name: 'Contact',
  data() {
    return {
      customSliderWrapperClass: '',
      customSlidesClass: ''
    }
  },
  metaInfo: {
    title: 'Mediabolaget Torsdagskväll',
    titleTemplate: '%s - Kontakt',
    htmlAttrs: {
      lang: 'sv',
      amp: true
    }
  },
  components: {
    HeadSlider
  },
  computed: {
    phoneLink() {
      return `tel:${this.$store.state.options.option.opt_conf_contact_phone.replace(/[^+\d]+/g, '')}`
    },
    mailtoLink() {
      return `mailto:${this.$store.state.options.option.opt_conf_contact_email}`
    },
    ...mapState(['contact', 'options'])
  }
}
</script>
