export const namespaced = true

let nextId = 1

const mutations = {
  PUSH(state, notification) {
    state.notifications.push({
      ...notification,
      id: nextId += 1
    })
  },
  DELETE(state, notificationToRemove) {
    state.notifications = state.notifications.filter(
      (notification) => notification.id !== notificationToRemove.id
    )
  }
}

const actions = {
  add({ commit }, notification) {
    commit('PUSH', notification)
  },
  remove({ commit }, notificationToRemove) {
    commit('DELETE', notificationToRemove)
  }
}

const getters = {
  // getEventById: (state) => {
  //   return (id) => {
  //     return state.events.find(event => event.id === id)
  //   }
  // }
}

const state = {
  notifications: []
}

export default {
  state,
  mutations,
  actions,
  getters
}
