import Vue from 'vue'
import Vuex from 'vuex'
import * as homepage from '@/store/modules/homepage'
import * as work from '@/store/modules/work'
import * as about from '@/store/modules/about'
import * as contact from '@/store/modules/contact'
import * as notification from '@/store/modules/notification'
import * as options from '@/store/modules/options'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    homepage,
    work,
    about,
    contact,
    notification,
    options
  },
  state: {
    testArray: [
      'sustainability',
      'nature',
      'animal welfare',
      'housing',
      'education',
      'food',
      'community'
    ]
  }
})

// check caching library: https://yarkovaleksei.github.io/vue2-storage/en/
