<template>
  <div class="pagewrapper PageComponent PageComponent--work">
    <div class="line"></div>
    <div class="grid-container">

      <div class="grid-x grid-margin-x">
        <div
          class="cell small-12 medium-6 wrapper-main-copytext"
          v-html="work.pagedata.post_content"
        ></div>
      </div>

      <template v-if="work.pagedata.portfolioItems">
      <div
        class="grid-x grid-margin-x"
        v-for="(item, index) in work.pagedata.portfolioItems"
        :key="index"
      >
        <div class="cell small-12 medium-6">
          <!-- <div class="slick-slider work-slider"> -->
          <swiper
            ref="headSlider"
            :options="swiperOption"
            :class="customSliderWrapperClass"
          >
            <swiper-slide
              v-for="slide in item.acf_slider_images"
              :key="slide.slide_num"
              class="slide-item"
            >
              <img :src="slide.slide_image_url" />
            </swiper-slide>
            <div class="swiper-pagination"  slot="pagination"></div>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
            <!-- <div class="swiper-scrollbar"   slot="scrollbar"></div> -->
          </swiper>
        </div>
        <div class="cell small-12 medium-6">
          <h2 class="title">{{ item.acf_headline }}</h2>
          <div v-html="item.acf_copytext"></div>
          <a
            v-if="item.acf_url"
            :href="item.acf_url"
            class="green"
            target="_blank"
          >
            {{ options.option.opt_ts_dict__website }}
          </a>
        </div>
      </div>
      </template>

    </div>
  </div>
</template>

<script>
// @TODO: use vuex store for JSON data
// @ is an alias to /src
import { mapState } from 'vuex'
import 'swiper/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  name: 'Home',
  data() {
    return {
      swiperOption: {
        // autoHeight: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      customSliderWrapperClass: 'SiperSlider SiperSlider--work'
    }
  },
  metaInfo: {
    title: 'Mediabolaget Torsdagskväll',
    titleTemplate: '%s - Uppdrag',
    htmlAttrs: {
      lang: 'sv',
      amp: true
    }
  },
  components: {
    swiper,
    swiperSlide
  },
  created() {
    // console.log('pagedate:', this.$store.state.work)
  },
  computed: {
    ...mapState(['work', 'options'])
  }
}
</script>
