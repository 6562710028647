import PagesService from '@/services/PagesService'

export const namespaced = true

export const mutations = {
  SET_OPTIONS_DATA(state, data) {
    state.option = data.acf
  }
}

export const actions = {
  fetchOptions({ commit, dispatch }) {
    PagesService.getOptions()
      .then((response) => {
        // console.log('Store Options fetch result:: ', response.data)
        commit('SET_OPTIONS_DATA', response.data)
      })
      .catch((error) => {
        const notification = {
          type: 'error',
          message: `There was a problem fetching option: ${error.message}`
        }
        dispatch('notification/add', notification, { root: true })
      })
  }
}

export const state = {
  option: {}
}
