<template>
  <div class="page-home">
    <template v-if="homepage.acf.slider_images_repeater">
      <div class="wrapper-mediastage">
        <HeadSlider
          :headSliderData="homepage.acf.slider_images_repeater"
          :customSliderWrapperClass="customSliderWrapperClass"
          :customSlidesClass="customSlidesClass"
        />
      </div>
    </template>

    <div class="grid-container">
      <div class="grid-x grid-margin-x">
        <div class="small-12 medium-8 cell" v-html="homepage.pagedata.post_content_wpautop"></div>
        <div class="small-12 medium-4 cell">
          <h2 class="contact-title">{{ options.option.opt_conf_contact_headline }}</h2>
          <span class="contact-name">{{ options.option.opt_conf_contact_name }}</span><br>
          {{ options.option.opt_conf_contact_label_phone }}
          <a
            v-if="options.option.opt_conf_contact_phone"
            :href="phoneLink"
            class="phonenumber"
          >
            {{ options.option.opt_conf_contact_phone }}
          </a><br>
          <a
            v-if="options.option.opt_conf_contact_email"
            :href="mailtoLink"
            class="email"
          >
            {{ options.option.opt_conf_contact_email }}
          </a>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// @TODO: use vuex store for JSON data
// @ is an alias to /src
import { mapState } from 'vuex'
import HeadSlider from '@/components/HeadSlider.vue'

export default {
  name: 'Home',
  data() {
    return {
      customSliderWrapperClass: '',
      customSlidesClass: ''
    }
  },
  metaInfo: {
    title: 'Mediabolaget Torsdagskväll',
    titleTemplate: '%s - Webbyrå i Tällberg',
    htmlAttrs: {
      lang: 'sv',
      amp: true
    },
    meta: [{
      name: 'description',
      content: 'Home sweet home :)'
    }]
  },
  components: {
    HeadSlider
  },
  created() {
    // this.$store.dispatch('homepage/fetchPagedata')
  },
  computed: {
    phoneLink() {
      return `tel:${this.$store.state.options.option.opt_conf_contact_phone.replace(/[^+\d]+/g, '')}`
    },
    mailtoLink() {
      return `mailto:${this.$store.state.options.option.opt_conf_contact_email}`
    },
    ...mapState(['homepage', 'options'])
  }
}
</script>
