<template>
  <div class="pagewrapper PageComponent PageComponent--about">
    <template v-if="about.acf.slider_images_repeater">
      <div class="wrapper-mediastage">
        <HeadSlider
          :headSliderData="about.acf.slider_images_repeater"
          :customSliderWrapperClass="customSliderWrapperClass"
          :customSlidesClass="customSlidesClass"
        />
      </div>
    </template>
    <div class="grid-container">
      <div class="grid-x grid-margin-x">
        <div
          class="cell small-12 wrapper-main-copytext"
          v-html="about.pagedata.post_content_wpautop"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import HeadSlider from '@/components/HeadSlider.vue'

export default {
  name: 'About',
  data() {
    return {
      customSliderWrapperClass: '',
      customSlidesClass: ''
    }
  },
  metaInfo: {
    title: 'Mediabolaget Torsdagskväll',
    titleTemplate: '%s - Om oss',
    htmlAttrs: {
      lang: 'sv',
      amp: true
    }
  },
  components: {
    HeadSlider
  },
  computed: {
    ...mapState(['about', 'options'])
  }
}
</script>
