import PagesService from '@/services/PagesService'

export const namespaced = true

export const mutations = {
  SET_WORK_DATA(state, data) {
    state.pagedata = data
  },
  SET_ACF_DATA(state, data) {
    state.acf = data
  }
}

export const actions = {
  fetchPagedata({ commit, dispatch }) {
    PagesService.getWork()
      .then((response) => {
        // console.log('Store HP fetch result:: ', response.data)
        commit('SET_WORK_DATA', response.data)
        commit('SET_ACF_DATA', response.data.acf)
      })
      .catch((error) => {
        const notification = {
          type: 'error',
          message: `There was a problem fetching work data: ${error.message}`
        }
        dispatch('notification/add', notification, { root: true })
      })
  }
}

export const state = {
  pagedata: {},
  acf: {}
}
