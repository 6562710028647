<template>
  <div class="wrapper-footer">

    <div class="grid-container">
      <div class="grid-x grid-margin-x">

        <div class="cell small-12 medium-4">
          <h3 class="heading-contact">{{ options.option.opt_conf_contact_headline }}</h3>
          <h4 class="heading-company">{{ options.option.opt_conf_contact_name_company }}</h4>
            {{ options.option.opt_conf_contact_street_and_number }}<br>
            {{ options.option.opt_conf_contact_zip }} {{ options.option.opt_conf_contact_city }}
        </div>

        <div class="cell small-12 medium-4 end box-partner">
          <h3 class="heading-partner">Partner</h3>
          <ul class="partner-list">
            <li class="item"><a class="footerlink" href="http://www.mikabo.de" target="_blank">Mikabo GbR Tyskland</a></li>
            <li class="item"><a class="footerlink" href="http://www.orca-gruppe.de" target="_blank">Orca Campain GmbH Tyskland</a></li>
          </ul>
        </div>

      </div>
    </div>

  </div><!-- /#wrapper_footer -->
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PageFooter',
  computed: {
    ...mapState(['options'])
  }
}
</script>
