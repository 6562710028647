<template>
  <div class="box-menu-main">
    <nav id="menu" class="wrapper-menu-main">
      <ul class="nav-main">
        <router-link
          v-for="route in routes"
          :key="route.path"
          :to="route.path"
          :name="route.name"

          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            class="nav-main-item"
            :class="[defaultListItemClass, isExactActive ? activeClass : '' ]"
          >
            <a
              :active="isActive"
              :href="href"
              @click="navigate"
              class="nav-main-link"
            >
              {{ route.buttonLabel }}
            </a>
          </li>
        </router-link>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'MenuPrimary',
  data() {
    return {
      routes: this.$router.options.routes,
      defaultListItemClass: 'nav-main-item',
      activeClass: 'current-menu-item'
    }
  }
}
</script>
